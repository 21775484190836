@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
body {
  margin: 0px;
  line-height: normal;
}
:root {
  /* fonts */
  --font: Inter;

  /* font sizes */
  --font5-size: 14px;
  --font1-size: 20px;
  --font6-size: 10px;
  --font-size: 13px;
  --font3-size: 15px;
  --font2-size: 24px;
  --font7-size: 16px;
  --font4-size: 32px;

  /* Colors */
  --fbfbfd: #fbfbfd;
  --f4f5fa: #f4f5fa;
  --ef61: #254ef6;
  --ef6: rgba(37, 78, 246, 0.2);
  --ef62: rgba(37, 78, 246, 0.05);
  --color1: #000851;
  --color: rgba(0, 8, 81, 0.2);
  --e8f5fe: #e8f5fe;
  --e8f0fd: #e8f0fd;
  --color-black: #000;

  /* Gaps */
  --gap-11xl: 30px;
  --gap-xl: 20px;
  --gap-mini: 15px;
  --gap-208xl: 227px;
  --gap-41xl: 60px;
  --gap-components: 10px;

  /* Paddings */
  --padding-mid: 17px;
  --padding-98xl: 117px;
  --padding-5xs: 8px;
  --padding-mini: 15px;
  

  /* Border radiuses */
  --br-xl: 20px;
  --br-xs: 12px;
  --br-3xs: 10px;
}

.screen {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
}

.logo {
  width: 100%;
  min-height: 1px;
  text-align: center;
}

.menu {
  width: 100%;
  min-height: 1px;
}

.mission {
  width: 100%;
  min-height: 1px;
  padding-top: var(--gap-components);
  padding-bottom: var(--gap-components);
}

.content {
  width: 100%;
  min-height: 1px;
  /*margin-right: 10px;*/
  /*padding-right: 10px;*/
  color: var(--color1);
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--gap-components);
  padding-bottom: var(--gap-components);
}

.feedback {
  width: 100%;
  max-width: 350px;
  display: flex;
  min-height: 1px;
  padding-top: var(--gap-components);
  padding-bottom: var(--gap-components);
  /*padding-left: var(--gap-components);*/
  /*margin-bottom: var(--gap-components);*/
  margin-left: auto;
  margin-right: auto;
}

.footer {
  width: 100%;
  min-height: 1px;
  padding-top: var(--gap-components);
  padding-bottom: var(--gap-components);
}

.menu_box {
  display: flex;
  flex-direction: row;
  /*justify-content: flex-start;*/
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  /*gap: var(--gap-41xl);*/
  /*gap: 1em;*/
  min-height: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  row-gap: 1em;
  column-gap: 3em;
}

.menu_item {
  text-decoration: none;
  margin: 4px;
  padding: 4px;
  border-radius: var(--br-xl);
}

.mission_box {
  /*position: absolute;*/
  border-radius: var(--br-xl);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  top: 107px;
  left: 320px;
  min-height: 143px;
  background-color: var(--f4f5fa);
  padding-left: 10px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  /*width: 1280px;
  height: 143px;*/
  overflow: hidden;
  font-size: var(--font2-size);
  color: var(--fbfbfd);
  background-image: url("../public/image-background.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 143px;
}

.mission_textbox {
  /*position: absolute;*/
  /*left: 30px;
  top: 30px;*/
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: auto;
  padding-left: 10px;
  /*padding: 5px;*/
  margin-top: 10px;
  /*padding-bottom: 10px;*/
  border-radius: var(--br-xs);
  background-color: var(--ef61);
  align-items: center;
  /*overflow: hidden;*/
  /*display: flex;
  flex-direction: row;
  justify-self: start;*/
  /*align-self: self-end;*/
  /*padding: var(--padding-5xs) var(--padding-mini);*/
}

.mission_text {
  /*position: relative;*/
  text-align: left;
  flex-wrap: wrap;
}

.mission_company {
  /*position: absolute;
  left: 30px;
  top: 95px;*/
  width: 30%;
  height: 30px;
  padding: 5px;
  align-self: flex-end;
  justify-self: start;
  font-size: var(--font3-size);
  color: var(--color1);
  text-align: left;
  /*justify-self: start;
  align-self: center;*/
}

.mission_background_image {
  /*position: absolute;*/
  top: 0;
  left: 801px;
  width: 479px;
  height: 143px;
  object-fit: cover;
}

.content_header {
  width: 100%;
  /*background-color: gray;
  color: black;
  width: 100%;*/
  height: 60px;
  font-size: var(--font4-size);
  color: var(--fbfbfd);
  border-radius: var(--br-xl);
  background-color: var(--color1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  padding-left: 30px;
  padding-top: 20px;
  margin-bottom: var(--gap-components);
  /*margin-right: var(--gap-components);*/
}

.content_header_text {
  /*padding-top: 11px;*/
  text-transform: uppercase;
}

.content_column {
  width: 50%;
  min-height: 1px;
  color: var(--color1);
  margin-top: var(--gap-components);
  margin-bottom: var(--gap-components);
}

.content_column_container_left {
  padding-right: var(--gap-components);
}

.content_column_container_right {
  padding-left: var(--gap-components);
}

.content_box {
  display: flex;
  flex-wrap: wrap;
  /*align-items: start;*/
  width: 100%;
  min-height: 1px;
  /*background-color: cyan;*/
  background-color: var(--fbfbfd);
  font-size: var(--font-size);
  color: var(--color1);
  font-family: var(--font);
}

.dev_serv {
  min-height: 60px;
  width: 100%;
}
.service_item {
  display: block;
  width: 180px;
  min-height: 1px;
  margin: 30px;
}

.service_item_img {
  width: 40px;
  height: 41px;
}

.technologies_item_img {
  width: 69px;
  height: 69px;
  object-fit: cover;
  margin: 15px;
}

.service_item_text {
  width: 100%;
  min-height: 1px;
  text-align: left;
  text-transform: uppercase;
}

.stage_item {
  display: flex;
  border-radius: var(--br-xl);
  background-color: var(--f4f5fa);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  width: 360px;
  height: 100px;
  font-size: var(--font1-size);
  margin: 20px;
  padding-left: 30px;
  overflow: hidden;
}

.stage_item_number {
  border-radius: var(--br-3xs);
  background-color: var(--ef6);
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-mini);
  box-sizing: border-box;
  text-align: center;
  color: var(--fbfbfd);
  margin-top: auto;
  margin-bottom: auto;
  font-size: var(--font2-size);
}

.stage_item_text {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  font-size: var(--font2-size);
}

.contact_container {
  display: flex;
  width: 100%;
  min-height: 1px;
  background-color: black;
}
.contact_item {
  display: flex;
  width: 100%;
  margin: 30px;
}

.contact_item_text {
  color: var(--color-black);
  font-size: var(--font2-size);
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 30px;
}

.contact_item_text:hover {
  cursor: pointer;
}

.contact_item_image {
  width: 39px;
  height: 40px;
}

.send_mail {
  text-decoration: none;
  color: black;
}

.project_conteiner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 1px;
  gap: 10px;
  /*height: 850px;*/
  overflow: hidden;
}

.project_conteiner_content {
  display: flex;
  min-width: 100%;
  height: 820px;
}

.project_conteiner_buttons {
  min-width: 100%;
  height: 30px;
}

.project_conteiner_content_main_image {
  border-radius: var(--br-xl);
  width: 290px;
  /*width: 40%;*/
  height: 350px;
  object-fit: cover;
}

.project_conteiner_content_main_text {
  border-radius: var(--br-xl);
  background-color: var(--e8f0fd);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  width: 290px;
  /*width: 40%;*/
  height: 350px;
  overflow: hidden;
  /*background-color: cadetblue;*/
  padding-left: 20px;
  background-image: url("../public/ellipse-25.svg"), 
                    url("../public/ellipse-26.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto 211px;             
}

.project_conteiner_content_main_text_header {
  display: inline-block;
  width: 230px;
}

.project_conteiner_content_main_text_stye {
  font-size: var(--font5-size);
  display: inline-block;
  width: 230px;
}

.project_conteiner_content_next_image_frame {
  /*width: 20%;*/
  width: 180px;
  height: 350px;
  display: block;
}

.project_conteiner_content_empty {
  border-radius: var(--br-xl);
  /*width: 180px;*/
  width: 100%;
  height: 191px;
  object-fit: cover;
}

.project_conteiner_content_next_image {
  display: flex;
  border-radius: var(--br-xl);
  padding: 0px;
  width: 180px;
  /*width: 100%;*/
  height: 159px;
  border-width: 0px;
  object-fit: cover;
  align-self: start;
  overflow: hidden;
  background-image: url("../public/look96.png");
  background-repeat: no-repeat;
  background-position: center;
}

.project_conteiner_content_next_image:hover {
  border-width: 2px;
  border-color: var(--color1);
  cursor: pointer;
}

.button_image {
  width: 180px;
  height: 159px;
  border-radius: var(--br-xl);
  object-fit: cover;
  opacity: 0.7;
}

.our_command,
.to_do {
  /*display: block;*/
  border-radius: var(--br-xl);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  /*width: 400px;*/
  width: 100%;
  height: 370px;
  background-color: var(--f4f5fa);
  overflow: hidden;
}

.our_command {
  margin-right: var(--gap-components);
}

.to_do {
  margin-left: var(--gap-components);
}

.our_command_item {
  border-radius: var(--br-xl);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  /*top: 0;
  left: 0;*/
  background-color: var(--e8f0fd);
  /*width: 400px;*/
  width: 100%;
  height: 370px;
  background-image: url("../public/ellipse-23.svg"),
                    url("../public/ellipse-24.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 181px;
}

.to_do_item {
  border-radius: var(--br-xl);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  /*top: 0;
  left: 0;*/
  /*background-color: var(--f4f5fa);*/
  background-color: var(--e8f5fe);
  /*width: 400px;*/
  width: 100%;
  height: 370px;
  background-image: url("../public/ellipse-23.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto 181px;
}

.our_command_header {
  display: inline-block;
  /*width: 340px;*/
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.our_command_paragraphs {
  min-height: 1px;
  width: 100%;
  padding-left: 30px;
  /*background-color: black;
  color: white;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  /*top: 108px;
  left: 30px;*/
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--font-size);
}

.our_command_paragraph_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.our_command_point {
  width: 10px;
  /*position: relative;*/
  border-radius: 50%;
  background-color: var(--color1);
  height: 10px;
}

.our_command_paragraph_text {
  /*position: relative;*/
  text-transform: uppercase;
}

.feedback_box {
  border-radius: var(--br-xl);
  background-color: var(--f4f5fa);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: 450px;
  /*padding-top: 20px;
  padding-bottom: 20px;*/
  font-size: var(--font1-size);
  margin-left: var(--gap-components);
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: flex-start;*/
  /*gap: var(--gap-11xl);*/
  gap: 15px;
  overflow: hidden;
}

.feedback_form_header {
  display: inline-block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #000851;
}

.feedback_form_field {
  width: 80%;
  height: 41px;
  padding-left: 20px;
  /*margin-left: 20px;
  margin-right: 20px;*/
  /*margin-left: auto;
  margin-right: auto;*/
  border-radius: var(--br-xl);
  background-color: var(--ef62);
  box-shadow: 0 2px 1.7px rgba(145, 150, 171, 0.21);
  border: 1px solid var(--ef6);
  box-sizing: border-box;
}

.feedback_form_field_text {
  width: 80%;
  height: 129px;
  padding-left: 20px;
  border-radius: var(--br-xl);
  background-color: var(--ef62);
  box-shadow: 0 2px 1.7px rgba(145, 150, 171, 0.21);
  border: 1px solid var(--ef6);
  box-sizing: border-box;
  /*width: 100%;*/
  /*height: 129px;*/
}

.button_send {
  width: 320px;
  border-radius: var(--br-xl);
  background-color: var(--ef61);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mid) var(--padding-98xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font5-size);
  color: var(--fbfbfd);
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
}

.button_send_title {
  text-transform: uppercase;
}

.footer_conteiner {
  height: 100%;
  width: auto;
  padding-top: var(--gap-components);
  padding-bottom: var(--gap-components);
  text-align: center;
  background-color: var(--color1);
  color: var(--fbfbfd);
}

.creator_href {
  color: white;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.active {
  color: white;
  background-color: var(--ef61);
}

@media (max-width: 498px) {
  .project_conteiner_content_empty {
    height: 1px;
  }
  .project_conteiner_content_next_image_frame {
    height: 159px; 
  }
}

@media (min-width: 609px) and (max-width: 798px) {
  .project_conteiner_content_empty {
    height: 1px;
  }
  .project_conteiner_content_next_image_frame {
    height: 159px; 
  }
}

@media (min-width: 990px) {
  body {
    margin-left: 30px;
    margin-right: 30px;
  }

  .logo {
    flex: 0 0 10%;
    max-width: 10%;
    order: 1;
  }

  .menu {
    flex: 0 0 90%;
    max-width: 90%;
    order: 2;
  }

  .mission {
    flex: 0 0 100%;
    max-width: 100%;
    order: 3;
  }

  .content {
    flex: 0 0 100%;
    max-width: 100%;
    order: 4;
  }

  .feedback {
    flex: 0 0 40%;
    max-width: 40%;
    order: 5;
    margin-left: auto;
    margin-right: auto;
  }

  .footer {
    flex: 0 0 100%;
    max-width: 100%;
    order: 6;
  }

  .mission_textbox {
    width: 60%;
  }
}

@media (min-width: 1300px) {
  
  body {
    margin-left: 30px;
    margin-right: 30px;
  }

  .logo {
    flex: 0 0 7%;
    max-width: 7%;
    order: 1;
  }

  .menu {
    flex: 0 0 93%;
    max-width: 93%;
    order: 2;
  }

  .mission {
    flex: 0 0 100%;
    max-width: 100%;
    order: 3;
  }

  .content {
    flex: 0 0 70%;
    max-width: 70%;
    order: 4;
  }

  .feedback {
    flex: 0 0 30%;
    max-width: 30%;
    order: 5;
    margin-left: auto;
    margin-right: auto;
  }

  .footer {
    flex: 0 0 100%;
    max-width: 100%;
    order: 6;
  }

  .mission_textbox {
    width: 70%;
  }
}

